<template>
    <div class="protection_units_table sn_tablebody">
        <p>考古发掘</p>
        <sn-table :snTable.sync="kgfjTableData" @handleChange="handleChange"></sn-table>
        <p>考古发掘历年经费</p>
        <sn-table :snTable.sync="kgfjjfTableData" @handleChange="handleChange"></sn-table>
        <p>保护工程历年经费</p>
        <sn-table :snTable.sync="bhgcjfTableData" @handleChange="handleChange"></sn-table>
        <sn-dialog :dialogData.sync="dialogData">
            <div class="table">
                <sn-table :snTable.sync="fileData" @handleChange="handleChange"></sn-table>
            </div>
        </sn-dialog>
        <sn-dialog :dialogData.sync="mapDialogData">
            <div class="map">
                <Mymap :map.sync="basicMap" :showSwitch="false" :showOprate="false" @initmap="initMap"></Mymap>
            </div>
        </sn-dialog>
    </div>
</template>
<script>
export default {
    name: 'protection_units_table',
    props: {
        kgfjData: {
            default() {
                return [];
            }
        },
        kgfjjfData: {
            default() {
                return [];
            }
        },
        bhgcjfData: {
            default() {
                return [];
            }
        }
    },
    data() {
        return {
            DOWN_URL: window.DOWN_URL,
            basicMap: null,
            areaLayers: null,
            gzddJson: '',
            kgfjTableData: {
                table: {
                    data: [],
                    rowKey: 'ID',
                },
                columns: {
                    // 选择、序号
                    columnsOperate: [{
                        isShow: true,
                        label: '序号',
                        type: 'index',
                        width: 50
                    }],
                    // 数据列
                    columnsData: [{
                        prop: 'xmmc',
                        label: '考古发掘项目',
                        showoverflowtooltip: true
                    }, {
                        prop: 'cclj',
                        label: '批准文件',
                        showFileBtn: true
                    }, {
                        prop: 'wwbmpzwh',
                        label: '批准文号'
                    }, {
                        prop: 'kgwzms',
                        label: '在遗址中的位置'
                    }, {
                        prop: 'kssj',
                        label: '工作时间'
                    }, {
                        prop: 'fjmj',
                        label: '工作面积'
                    }, {
                        prop: 'wz',
                        label: '工作地点坐标'
                    }],
                },
            },
            kgfjjfTableData: {
                table: {
                    data: [],
                    rowKey: 'ID',
                },
                columns: {
                    // 选择、序号
                    columnsOperate: [{
                        isShow: true,
                        label: '序号',
                        type: 'index',
                        width: 50
                    }],
                    // 数据列
                    columnsData: [{
                        prop: 'nf',
                        label: '年份',
                    }, {
                        prop: 'kgfj',
                        label: '项目类型'
                    }, {
                        prop: 'xmmc',
                        label: '项目名称'
                    }, {
                        prop: 'zyczjf',
                        label: '中央财政经费'
                    }, {
                        prop: 'shengczjf',
                        label: '省财政经费'
                    }, {
                        prop: 'shiczjf',
                        label: '市财政经费'
                    }, {
                        prop: 'syxczjf',
                        label: '市以下财政经费',
                        minWidth: 100
                    }, {
                        prop: 'zcjf',
                        label: '自筹经费'
                    }, {
                        prop: 'jftrze',
                        label: '总经费'
                    }],
                },
            },
            bhgcjfTableData: {
                table: {
                    data: [],
                    rowKey: 'ID',
                },
                columns: {
                    // 选择、序号
                    columnsOperate: [{
                        isShow: true,
                        label: '序号',
                        type: 'index',
                        width: 50
                    }],
                    // 数据列
                    columnsData: [{
                        prop: 'nf',
                        label: '年份',
                    }, {
                        prop: 'gcfl',
                        label: '项目类型'
                    }, {
                        prop: 'gcmc',
                        label: '项目名称'
                    }, {
                        prop: 'zyczjf',
                        label: '中央财政经费'
                    }, {
                        prop: 'shengczjf',
                        label: '省财政经费'
                    }, {
                        prop: 'shiczjf',
                        label: '市财政经费'
                    }, {
                        prop: 'syxczjf',
                        label: '市以下财政经费',
                        minWidth: 100
                    }, {
                        prop: 'zcjf',
                        label: '自筹经费'
                    }, {
                        prop: 'bhgcgjbzjf',
                        label: '保护工程经费-国家补助',
                        minWidth: 100
                    }, {
                        prop: 'bhgcdfptjf',
                        label: '保护工程经费-地方配套'
                    }, {
                        prop: 'gjtrzjf',
                        label: '总经费'
                    }],
                },
            },
            fileData: {
                table: {
                    data: [],
                    showHeader: false
                },
                columns: {
                    // 选择、序号
                    columnsOperate: [{
                        isShow: true,
                        type: 'selection',
                        width: 35
                    }],
                    // 数据列
                    columnsData: [{
                        prop: 'CCLJ',
                        label: '附件',
                        width: 90,
                        showFileBtn: true,
                        operateType: 'dialogFileBtn'
                    }, {
                        prop: 'WDMC',
                        label: '基础数据名称'
                    }],
                }
            },
            dialogData: {
                title: '附件列表',
                dialog: false
            },
            mapDialogData: {
                title: '工作地点坐标',
                dialog: false
            }
        };
    },
    watch: {
        kgfjData() {
            this.kgfjData.forEach(item => {
                item.wz = item.xmwzsl ? '点击查看' : '';
            });
            this.kgfjTableData.table.data = this.kgfjData;
        },
        kgfjjfData() {
            this.kgfjjfTableData.table.data = this.kgfjjfData;
        },
        bhgcjfData() {
            this.bhgcjfTableData.table.data = this.bhgcjfData;

        }
    },
    mounted() {
    },
    methods: {
        handleChange(type, value) {
            console.log(type, value);
            this[type](value);
        },
        // 查看批准文件
        fileBtn(value) {
            let list = value[0].cclj;
            let data = list.split(',');
            let wdmc = value[0].wdmc.split(',');
            let num = data.length;
            if (num === 1) {
                // 直接点开或者下载
                window.open(list);
            } else {
                let tableData = [];
                data.forEach((item, index) => {
                    tableData.push({
                        WDMC: wdmc[index],
                        CCLJ: item
                    });
                });
                this.fileData.table.data = tableData;
                this.dialogData.dialog = true;
            }
        },
        // 点击查看工作地点坐标
        textClick(value) {
            let xmwzsl = value[0].xmwzsl;
            this.gzddJson = xmwzsl;
            this.mapDialogData.dialog = true;
            this.initZone();
        },
        rowClick() {

        },
        // 点击附件下载按钮
        dialogFileBtn(value) {
            window.open(value[0].CCLJ);
        },
        initZone() { // 渲染工作地点范围
            let _this = this;
            if (!this.gzddJson || !this.areaLayers) return false;
            this.areaLayers.clearLayers();
            let geoJson = JSON.parse(this.gzddJson);
            // eslint-disable-next-line
            L.geoJSON(geoJson, {
                onEachFeature: function(feature, layer) {
                    layer.selfType = 'polygon';
                    _this.areaLayers.addLayer(layer);
                },
                style(feature) {
                    return {
                        color: '#1862ad',
                        fillColor: '#1862ad',
                        weight: 2
                    };
                }
            });
        },
        initMap(map) {
            this.basicMap = map;
            // eslint-disable-next-line
            this.areaLayers = L.featureGroup().addTo(this.basicMap);
            this.initZone();
        }
    }
};
</script>
<style lang="scss" scoped>
.protection_units_table {
    p {
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 14px;
        margin-top: 10px;
    }
    .map {
        width: 100%;
        height: 600px;
        position: relative;
    }
}
</style>