<template>
    <div class="">
        <sn-dialog class="zyzcDialog" :dialogData.sync="zyzcDialogData">
            <el-form
                ref="ZCinfo"
                :inline="true"
                class="demo-form-inline dialog-form-inline form_row"
                size="small"
                label-width="150px"
                :model="ZCinfo"
                :rules="rules"
                :show-message="rule"
            >
                <h5>基本信息</h5>
                <el-form-item label="文物点名称" prop="wwdmc">
                    <el-input
                        v-model="ZCinfo.wwdmc"
                        placeholder="请输入文物点名称"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="文物朝代" prop="wwsd">
                    <el-input
                        v-model="ZCinfo.wwsd"
                        placeholder="请输入文物朝代"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="具体年代" prop="jtnd">
                    <el-input
                        v-model="ZCinfo.jtnd"
                        placeholder="请输入具体年代"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="文物组成类型">
                    <el-select
                        v-model="ZCinfo.wwzclx"
                        filterable
                        clearable
                        placeholder="请选择文物组成类型"
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(ele, index) in wwzclxList"
                            :key="index"
                            :value="ele"
                            :label="ele"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="具体地址" prop="jtdz">
                    <el-input
                        v-model="ZCinfo.jtdz"
                        placeholder="请输入具体地址"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="建筑面积" prop="jzmj">
                    <el-input
                        v-model="ZCinfo.jzmj"
                        placeholder="请输入建筑面积"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    >
                        <template slot="append">㎡</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="占地面积" prop="zdmj">
                    <el-input
                        v-model="ZCinfo.zdmj"
                        placeholder="请输入占地面积"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    >
                        <template slot="append">㎡</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="保存方式" prop="bcfs">
                    <el-input
                        v-model="ZCinfo.bcfs"
                        placeholder="请输入保存方式"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="保存状况">
                    <el-select
                        v-model="ZCinfo.bczk"
                        filterable
                        clearable
                        placeholder="请选择保存状况"
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(ele, index) in bczkList"
                            :key="index"
                            :value="ele"
                            :label="ele"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="损毁原因" prop="shyy">
                    <el-input
                        v-model="ZCinfo.shyy"
                        placeholder="请输入损毁原因"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <h5>所有权情况</h5>
                <el-form-item label="所有权单位(人)名称" prop="syqdwmc">
                    <el-input
                        v-model="ZCinfo.syqdwmc"
                        placeholder="请输入所有权单位(人)名称"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="所有权性质">
                    <el-select
                        v-model="ZCinfo.syqxz"
                        filterable
                        clearable
                        placeholder="请选择所有权性质"
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(ele, index) in syqxzList"
                            :key="index"
                            :value="ele"
                            :label="ele"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="隶属上级部门名称">
                    <el-input
                        v-model="ZCinfo.syqdwlssjbmmc"
                        :disabled="!isEdit"
                        placeholder="请输入隶属上级部门名称"
                        onkeydown="if(event.keyCode==32) return false"
                    ></el-input>
                </el-form-item>
                <el-form-item label="所属行业">
                    <el-select
                        v-model="ZCinfo.syqdwsshy"
                        filterable
                        clearable
                        placeholder="请选择所属行业"
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="item in Work"
                            :key="item.ID"
                            :value="item.MC"
                            :label="item.MC"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <br />
                <el-form-item class="footer">
                    <sn-button
                        type="primary"
                        :snButton.sync="zyzcButton"
                        @handleChange="handleChange"
                    ></sn-button>
                </el-form-item>
            </el-form>
        </sn-dialog>
    </div>
</template>

<script>
// import infoMixin from "../0_com_js/info_mixin.js";
import { mapActions } from "vuex";
export default {
    name: "protection_units_zyzc_dialog",
    components: {},
    props: {
        isEdit: {
            default() {
                return true;
            },
        },
        bczkList: {
            default() {
                return [];
            },
        },
        syqxzList: {
            default() {
                return [];
            },
        },
        Work: {
            default() {
                return [];
            },
        },
    },
    // mixins: [infoMixin],
    data() {
        let checkNum = (rule, value, callback) => {
            if (value === "") {
                callback();
            } else if (isNaN(value)) {
                callback(new Error("请输入数字"));
            } else {
                callback();
            }
        };
        return {
            zyzcDialogData: {
                dialog: false,
                title: "所有权信息",
                class: "deepBlueDialog",
            },
            zyzcInfo: {
                // 主要组成模板
                // id: '',
                // dwid: '', // 单位id
                // dwmc: '', // 单位名称
                wwdmc: "",
                wwsd: "",
                jtnd: "",
                wwzclx: "",
                jtdz: "",
                jzmj: null,
                zdmj: null,
                bcfs: "",
                bczk: "",
                shyy: "",
                syqdwmc: "",
                syqxz: "",
                syqdwlssjbmmc: "",
                syqdwsshy: "",
                sfdwkf: null, // 是否对外开放
                njykl: null, // 年均游客量
                rzdykczl: null, // 日最大游客承载量
                njmpsr: null, // 年均门票收入
                sfwssmjdbxww: null, // 是否为少数民族代表性文物
            },
            ZCinfo: {}, // 主要组成信息
            type: "add",
            rules: {
                wwdmc: [
                    {
                        required: true,
                        message: "请填写文物点名称",
                        trigger: "blur",
                    },
                ],
                jzmj: [{ validator: checkNum, trigger: "blur" }],
                zdmj: [{ validator: checkNum, trigger: "blur" }],
            },
            wwzclxList: ["古墓葬", "古建筑", "石窟寺及石刻", "近现代", "其他"],
            zyzcButton: {
                buttonData: [
                    {
                        isShow: false,
                        btnType: "button",
                        operateType: "buttonDelete",
                        name: "删除该条组成信息",
                        round: true,
                        backColor: "#6f32ff",
                        color: "#fff",
                    },
                    {
                        isShow: true,
                        btnType: "button",
                        operateType: "buttonBack",
                        name: "返回",
                        round: true,
                        backColor: "#FF9065",
                        color: "#fff",
                    },
                    {
                        isShow: true,
                        btnType: "button",
                        operateType: "buttonSave",
                        name: "确定",
                        round: true,
                        backColor: "#2059CC",
                        color: "#fff",
                    },
                ],
            },
            rule: true,
        };
    },
    computed: {},
    watch: {},
    mounted() {},
    methods: {
        ...mapActions([]),
        async openZYZCDialog(zcInfo) {
            // 查看主要组成情况 TODO
            this.zyzcButton.buttonData[2].isShow = this.isEdit;
            if (zcInfo) {
                // 编辑
                this.ZCinfo = zcInfo;
                this.type = "edit";
                this.zyzcButton.buttonData[0].isShow = this.isEdit
                    ? true
                    : false; // 是否显示删除按钮
            } else {
                let info = JSON.parse(JSON.stringify(this.zyzcInfo));
                this.type = "add";
                this.ZCinfo = info;
            }
            this.zyzcDialogData.dialog = true;
        },
        handleChange(type) {
            this[type]();
        },
        buttonBack() {
            this.zyzcDialogData.dialog = false;
        },
        buttonDelete() {
            this.$emit("deleteZYZCItem");
            this.zyzcDialogData.dialog = false;
        },
        async buttonSave() {
            this.$refs.ZCinfo.validate((valid) => {
                if (valid) {
                    if (this.type === "add") {
                        this.$emit("addZYZCItem", this.ZCinfo);
                    }
                    this.zyzcDialogData.dialog = false;
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
h5 {
    font-size: 14px;
    font-weight: 400;
    color: #00a0e9;
    padding-left: 3px;
    border-bottom: 1px solid #00a0e9;
    margin-bottom: 16px;
}
.footer {
    border-top: 1px dashed #bacbdf;
    text-align: right;
    padding-top: 20px;
    width: 100% !important;
}
</style>
