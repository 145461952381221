<template>
    <div class="">
        <sn-dialog class="syqDialog" :dialogData.sync="syqDialogData">
            <el-form
                ref="SYQinfo"
                :inline="true"
                class="demo-form-inline dialog-form-inline form_row"
                size="small"
                label-width="150px"
                :model="SYQinfo"
                :rules="rules"
                :show-message="rule"
            >
                <el-form-item label="所有权单位(人)名称" prop="syqdwmc">
                    <el-input
                        v-model="SYQinfo.syqdwmc"
                        placeholder="请输入所有权单位(人)名称"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="所有权性质">
                    <el-select
                        v-model="SYQinfo.syqxz"
                        filterable
                        clearable
                        placeholder="请选择所有权性质"
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(ele, index) in syqxzList"
                            :key="index"
                            :value="ele"
                            :label="ele"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="隶属上级部门名称">
                    <el-input
                        v-model="SYQinfo.lssjbmmc"
                        :disabled="!isEdit"
                        placeholder="请输入隶属上级部门名称"
                        onkeydown="if(event.keyCode==32) return false"
                    ></el-input>
                </el-form-item>
                <el-form-item label="所属行业">
                    <el-select
                        v-model="SYQinfo.sshy"
                        filterable
                        clearable
                        placeholder="请选择所属行业"
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="item in Work"
                            :key="item.ID"
                            :value="item.MC"
                            :label="item.MC"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item class="footer">
                    <sn-button
                        type="primary"
                        :snButton.sync="syqButton"
                        @handleChange="handleChange"
                    ></sn-button>
                </el-form-item>
            </el-form>
        </sn-dialog>
    </div>
</template>

<script>
// import infoMixin from "../0_com_js/info_mixin.js";
import { mapActions } from "vuex";
export default {
    name: "protection_units_syq_dialog",
    components: {},
    props: {
        isEdit: {
            default() {
                return true;
            },
        },
        syqxzList: {
            default() {
                return [];
            },
        },
        Work: {
            default() {
                return [];
            },
        },
        SYQinfo: {
            // 所有权单位弹出框信息
            default() {
                return {};
            },
        },
    },
    // mixins: [infoMixin],
    data() {
        return {
            syqDialogData: {
                dialog: false,
                title: "所有权信息",
                class: "deepBlueDialog",
            },
            rules: {
                syqdwmc: [
                    {
                        required: true,
                        message: "请填写所有权单位(人)名称",
                        trigger: "blur",
                    },
                ],
            },
            syqButton: {
                buttonData: [
                    {
                        isShow: true,
                        btnType: "button",
                        operateType: "buttonBack",
                        name: "返回",
                        round: true,
                        backColor: "#FF9065",
                        color: "#fff",
                    },
                    {
                        isShow: true,
                        btnType: "button",
                        operateType: "buttonSave",
                        name: "确定",
                        round: true,
                        backColor: "#2059CC",
                        color: "#fff",
                    },
                ],
            },
            rule: true,
        };
    },
    computed: {},
    mounted() {},
    methods: {
        ...mapActions([]),
        async openSYQDialog(type) {
            // 查看文保单位所有权情况 TODO
            this.syqButton.buttonData[1].isShow = this.isEdit;
            this.syqDialogData.dialog = true;
        },
        handleChange(type) {
            this[type]();
        },
        buttonBack() {
            this.syqDialogData.dialog = false;
        },
        async buttonSave() {
            this.syqDialogData.dialog = false;
            this.$refs.SYQinfo.validate((valid) => {
                if (valid) {
                    this.$emit("saveSyqInfo");
                    this.syqDialogData.dialog = false;
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.footer {
    border-top: 1px dashed #bacbdf;
    text-align: right;
    padding-top: 20px;
    width: 100% !important;
}
</style>
