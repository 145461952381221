<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 提示信息 -->
            <alert-info :info="infodata"></alert-info>
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title"
                    >遗产地和各级文保单位的对应关系信息</span
                >
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.saveJson.wbdwjbxx"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item label="文物保护单位名称" prop="dwmc">
                    <el-input
                        v-model="formdata.saveJson.wbdwjbxx.dwmc"
                        placeholder="请输入文物保护单位名称"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="单位编号">
                    <el-input
                        v-model="formdata.saveJson.wbdwjbxx.dwbh"
                        placeholder="请输入单位编号"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="所属省份">
                    <el-input
                        v-model="formdata.saveJson.wbdwjbxx.sssf"
                        placeholder="请输入所属省份"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <br />
                <el-form-item label="单位简介">
                    <el-input
                        v-model="formdata.saveJson.wbdwjbxx.dwjj"
                        placeholder="请输入单位简介"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                        type="textarea"
                        :autosize="{ minRows: 6 }"
                        show-word-limit
                    >
                    </el-input>
                </el-form-item>
                <!-- 只上传一张即可 -->
                <el-form-item label="文物保护单位照片">
                    <el-upload
                        :action="`${BASE_URL}UpLoad/FileSave`"
                        accept="image/png,image/jpg,image/jpeg"
                        list-type="picture-card"
                        :on-preview="handlePictureCardPreview"
                        :on-remove="handleRemove"
                        :file-list="fileList"
                        :limit="1"
                        :class="{
                            noneupload: fileList.length === 1 || !isEdit,
                        }"
                        :on-success="handleAvatarSuccess"
                        :disabled="!isEdit"
                    >
                        <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                        <img width="100%" :src="dialogImageUrl" alt="" />
                    </el-dialog>
                </el-form-item>
                <el-form-item label="公布批次" prop="gbpc">
                    <el-select
                        v-model="formdata.saveJson.wbdwjbxx.gbpc"
                        filterable
                        clearable
                        placeholder="请选择公布批次"
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(ele, index) in gbpcList"
                            :key="index"
                            :label="ele"
                            :value="ele"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="公布级别" prop="gbjb">
                    <el-select
                        v-model="formdata.saveJson.wbdwjbxx.gbjb"
                        filterable
                        clearable
                        placeholder="请选择公布级别"
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(ele, index) in gbjbList"
                            :key="index"
                            :label="ele"
                            :value="ele"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="公布类型" prop="gblx">
                    <el-select
                        v-model="formdata.saveJson.wbdwjbxx.gblx"
                        filterable
                        clearable
                        placeholder="请选择公布类型"
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(ele, index) in gblxList"
                            :key="index"
                            :label="ele"
                            :value="ele"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="所属类型" prop="sslx">
                    <el-select
                        v-model="formdata.saveJson.wbdwjbxx.sslx"
                        filterable
                        clearable
                        placeholder="请选择所属类型"
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(ele, index) in sslxList"
                            :key="index"
                            :label="ele"
                            :value="ele"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="所属朝代">
                    <el-input
                        v-model="formdata.saveJson.wbdwjbxx.sscd"
                        placeholder="请输入所属朝代"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="公布时代">
                    <el-input
                        v-model="formdata.saveJson.wbdwjbxx.gbsd"
                        placeholder="请输入公布时代"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="公布地址">
                    <el-input
                        v-model="formdata.saveJson.wbdwjbxx.gbdz"
                        placeholder="请输入公布地址"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="详细地址">
                    <el-input
                        v-model="formdata.saveJson.wbdwjbxx.xxdz"
                        placeholder="请输入详细地址"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="所有权单位(人)名称" prop="syqdwmc">
                    <!-- <el-input v-model="formdata.saveJson.wbdwjbxx.syqdwmc" readonly>
                        <template slot="append"> -->
                    <!-- TODO -->
                    <!-- <el-button v-if="formdata.saveJson.wbdwjbxx.dwmc && formdata.saveJson.wbdwjbxx.syqdwmc || !isEdit"
                                       type="primary"
                                       @click="openSYQDialog('see')"
                            >
                                查看文保单位所有权单位（人）基本信息
                            </el-button> -->
                    <!-- <a v-if="formdata.saveJson.wbdwjbxx.dwmc && formdata.saveJson.wbdwjbxx.syqdwmc || !isEdit"
                       class="gray-style"
                       title="查看文保单位所有权单位（人）基本信息"
                       @click="openSYQDialog('see')"
                    >
                        {{formdata.saveJson.wbdwjbxx.syqdwmc}}
                    </a> -->
                    <el-input
                        v-if="
                            (formdata.saveJson.wbdwjbxx.dwmc &&
                                formdata.saveJson.wbdwjbxx.syqdwmc) ||
                            !isEdit
                        "
                        v-model="formdata.saveJson.wbdwjbxx.syqdwmc"
                        title="点击查看文保单位所有权单位（人）基本信息"
                        readonly
                        @focus="openSYQDialog('see')"
                    ></el-input>
                    <el-button
                        v-if="!formdata.saveJson.wbdwjbxx.syqdwmc && isEdit"
                        :disabled="!formdata.saveJson.wbdwjbxx.dwmc"
                        type="primary"
                        @click="openSYQDialog('add')"
                    >
                        <i class="el-icon-circle-plus"></i
                        >添加文保单位所有权单位（人）基本信息
                    </el-button>
                    <!-- </template>
                    </el-input> -->
                </el-form-item>
                <el-form-item label="管理机构" prop="gljg">
                    <el-select
                        v-model="formdata.saveJson.wbdwjbxx.gljg"
                        filterable
                        clearable
                        placeholder="请选择管理机构"
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(ele, index) in gljgList"
                            :key="index"
                            :label="ele.JGMC"
                            :value="ele.ID"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="经纬度" class="select_jwd" prop="jwd">
                    <el-tooltip
                        class="item"
                        effect="light"
                        content="请填写正确的经纬度坐标，经度和纬度用英文的逗号隔开，如：118.056786,24.435336或 118.071978,24.444005；或者点击“坐标拾取”按钮，直接获取经度坐标"
                        placement="bottom"
                    >
                        <el-input
                            v-model="formdata.saveJson.wbdwjbxx.jwd"
                            placeholder="请输入经纬度"
                            onkeydown="if(event.keyCode==32) return false"
                            :disabled="!isEdit"
                        ></el-input>
                    </el-tooltip>
                    <el-button size="small" @click="showPickup = true"
                        >坐标拾取</el-button
                    >
                </el-form-item>
                <el-form-item label="海拔高程" prop="hbgc">
                    <el-input
                        v-model="formdata.saveJson.wbdwjbxx.hbgc"
                        placeholder="请输入海拔高程"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    >
                        <template slot="append">m</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="测点说明">
                    <el-input
                        v-model="formdata.saveJson.wbdwjbxx.cdsm"
                        placeholder="请输入测点说明"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                        type="textarea"
                        maxlength="200"
                        :autosize="{ minRows: 6 }"
                        show-word-limit
                    >
                    </el-input>
                </el-form-item>
                <br />
                <el-form-item label="建筑面积" prop="jzmj">
                    <el-input
                        v-model="formdata.saveJson.wbdwjbxx.jzmj"
                        placeholder="请输入建筑面积"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    >
                        <template slot="append">㎡</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="占地面积" prop="zdmj">
                    <el-input
                        v-model="formdata.saveJson.wbdwjbxx.zdmj"
                        placeholder="请输入占地面积"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    >
                        <template slot="append">㎡</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="主要组成">
                    <a
                        v-for="(item, index) in formdata.saveJson.wbdwzyzclist"
                        :key="index"
                        class="gray-style"
                        title="查看组成详细信息"
                        @click="openZC(item, index)"
                    >
                        {{ item.wwdmc }},
                    </a>
                    <el-button
                        v-if="isEdit"
                        :disabled="formdata.saveJson.wbdwjbxx.dwmc === ''"
                        type="primary"
                        @click="addZYZC"
                    >
                        <i class="el-icon-circle-plus"></i>添加主要组成详细信息
                    </el-button>
                </el-form-item>
                <el-form-item label="保存状况" prop="bczk">
                    <el-select
                        v-model="formdata.saveJson.wbdwjbxx.bczk"
                        filterable
                        clearable
                        placeholder="请选择保存状况"
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(ele, index) in bczkList"
                            :key="index"
                            :label="ele"
                            :value="ele"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="保存方式">
                    <el-input
                        v-model="formdata.saveJson.wbdwjbxx.bcfs"
                        placeholder="请输入保存方式"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="损毁原因">
                    <el-input
                        v-model="formdata.saveJson.wbdwjbxx.shyy"
                        placeholder="请输入损毁原因"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
            </el-form>
            <tableData
                v-show="formdata.saveJson.wbdwjbxx.shzt === '2'"
                :kgfjData.sync="formdata.saveJson.kgfj"
                :kgfjjfData.sync="formdata.saveJson.jf_kgfj"
                :bhgcjfData.sync="formdata.saveJson.jf_bhgc"
            ></tableData>
        </div>
        <coordinatePickup
            :editState="isEdit"
            :showPickupP.sync="showPickup"
            :coordinate.sync="formdata.saveJson.wbdwjbxx.jwd"
            @clickPoint="clickPoint"
        ></coordinatePickup>
        <syqDialog
            ref="syqDialog"
            :isEdit="isEdit"
            :syqxzList="syqxzList"
            :Work="Work"
            :SYQinfo.sync="formdata.saveJson.wbdwxsyqk"
            @saveSyqInfo="saveSyqInfo"
        ></syqDialog>
        <zyzcDialog
            ref="zyzcDialog"
            :isEdit="isEdit"
            :bczkList="bczkList"
            :syqxzList="syqxzList"
            :Work="Work"
            @addZYZCItem="addZYZCItem"
            @deleteZYZCItem="deleteZYZCItem"
        ></zyzcDialog>
    </div>
</template>

<script>
import snButton from "../0_com_js/edit-button.js";
import infoMixin from "../0_com_js/info_mixin.js";
import { mapActions } from "vuex";
import syqDialog from "./protection_units_syq_dialog";
import zyzcDialog from "./protection_units_zyzc_dialog";
import tableData from "./protection_units_table";
import coordinatePickup from "@comp/map/coordinate_pickup";
export default {
    name: "assembly_resolutions",
    components: {
        syqDialog,
        zyzcDialog,
        tableData,
        coordinatePickup,
    },
    mixins: [infoMixin],
    data() {
        let checkCoordinate = (rule, value, callback) => {
            let reg2 = /^([0-9.]+,)[0-9.]+$/;
            if (value && !reg2.test(value)) {
                callback(new Error("请输入正确格式的经纬度"));
            } else {
                callback();
            }
        };
        let checkNum = (rule, value, callback) => {
            if (value === "") {
                callback();
            } else if (isNaN(value)) {
                callback(new Error("请输入数字"));
            } else {
                callback();
            }
        };
        return {
            snButton: snButton,
            notNeedFile: true,
            BASE_URL: window.REQUEST_URL,
            showPickup: false,
            rules: {
                dwmc: [
                    {
                        required: true,
                        message: "请填写文保单位名称",
                        trigger: "blur",
                    },
                ],
                // gbpc: [{ required: true, message: "请选择公布批次", trigger: "change" }],
                gbjb: [
                    {
                        required: true,
                        message: "请选择公布级别",
                        trigger: "change",
                    },
                ],
                sslx: [
                    {
                        required: true,
                        message: "请选择所属类型",
                        trigger: "change",
                    },
                ],
                jwd: [{ validator: checkCoordinate, trigger: "change" }],
                hbgc: [{ validator: checkNum, trigger: "blur" }],
                jzmj: [{ validator: checkNum, trigger: "blur" }],
                zdmj: [{ validator: checkNum, trigger: "blur" }],
            },
            gbjbList: [
                "全国重点文物保护单位",
                "省级文物保护单位",
                "市县级及以下文物保护单位",
                "尚未公布为文物保护单位的不可移动文物",
            ],
            gbpcList: [
                "第一批",
                "第二批",
                "第三批",
                "第四批",
                "第五批",
                "第六批",
                "第七批",
            ],
            gblxList: [
                "古墓葬",
                "古建筑",
                "石窟寺及石刻",
                "近现代",
                "其他",
                "古遗址",
            ],
            sslxList: [
                "聚落址",
                "洞穴遗址",
                "城址",
                "建筑遗址",
                "矿冶遗址",
                "陶瓷窑址",
                "其他遗址",
                "墓群",
                "单体墓葬",
                "烈士墓",
                "丛葬",
                "长城",
                "阙",
                "塔",
                "桥梁",
                "寺庙",
                "坛庙",
                "城郭",
                "衙署",
                "寨堡",
                "营垒",
                "宅第",
                "建筑群落",
                "会馆祠堂",
                "牌坊",
                "亭台楼阁",
                "水利天文",
                "邮驿交通",
                "书院学堂",
                "图博公益",
                "商肆",
                "作坊工场",
                "纪念碑",
                "其他建筑",
                "石窟",
                "摩崖",
                "造像",
                "岩画",
                "经幢",
                "碑刻",
                "雕刻",
                "其他",
            ],

            syqxzList: [
                "国有",
                "集体",
                "混合（国有、集体、私有）",
                "混合（集体、私有）",
                "混合（国有、集体）",
                "私有",
                "混合（国有、私有）",
                "其他",
            ],
            Work: [
                { ID: "1", MC: "文物" },
                { ID: "2", MC: "党政机关" },
                { ID: "3", MC: "文化" },
                { ID: "4", MC: "当地看管" },
                { ID: "5", MC: "宗教" },
                { ID: "6", MC: "旅游" },
                { ID: "7", MC: "教育" },
                { ID: "8", MC: "多重部门" },
                { ID: "9", MC: "工业" },
                { ID: "10", MC: "林业" },
                { ID: "11", MC: "城建" },
                { ID: "12", MC: "军队" },
                { ID: "13", MC: "交通" },
                { ID: "14", MC: "商业" },
                { ID: "15", MC: "金融" },
                { ID: "16", MC: "民政" },
                { ID: "17", MC: "其他" },
            ],
            gljgList: [],
            bczkList: ["较好", "好", "一般", "较差", "差", "其他"],
            fileList: [],
            dialogImageUrl: "",
            dialogVisible: false,
            nowZcName: "", // 现在打开的组成弹框
            nowZcIndex: -1, // 现在打开的组成弹框索引
            dialogVisibleZC: false, // 主要组成弹框
            formdata: {
                saveJson: {
                    wbdwjbxx: {
                        id: "",
                        shzt: 1,
                        dwmc: "",
                        dwbh: "",
                        sssf: "云南省",
                        dwjj: "",
                        gbpc: "",
                        gbjb: "",
                        gblx: "古建筑",
                        sslx: "",
                        sscd: "",
                        gbsd: "",
                        gbdz: "",
                        xxdz: "",
                        syqdwmc: "",
                        gljg: "",
                        jd: "",
                        wd: "",
                        jwd: "",
                        hbgc: null,
                        cdsm: "",
                        jzmj: null,
                        zdmj: null,
                        bczk: "",
                        bcfs: "",
                        shyy: "",
                        dbxtp: "",
                        CJRID: localStorage.userId,
                        RWID: this.$route.query.RWID,
                        jd: "",
                        wd: "",
                    },
                    wbdwzyzclist: [],
                    wbdwxsyqk: {
                        // id: '',
                        // dwid: '', // 单位id
                        // dwmc: '', // 单位名称
                        syqdwmc: "", // 所有权单位名称
                        syqxz: "",
                        lssjbmmc: "",
                        sshy: "",
                    },
                },
                metaJson: [],
                heritageId: "",
                fileID: "",
                originalFileID: "",
                itemID: "8",
                userName: "",
                userId: "",
            },
        };
    },
    computed: {},
    watch: {
        "formdata.saveJson.wbdwjbxx.dbxtp": function () {
            this.getPicList();
        },
    },
    mounted() {
        this.getPicList();
        this.getGLJGList();
    },
    methods: {
        ...mapActions([]),
        getPicList() {
            let pic = this.formdata.saveJson.wbdwjbxx.dbxtp;
            if (pic) {
                this.fileList = [{ url: pic }];
            }
        },
        handleRemove() {
            this.fileList = [];
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        handleAvatarSuccess(res, file) {
            this.fileList.push(file);
            this.formdata.saveJson.wbdwjbxx.dbxtp = res.ResultValue[0].FilePath; // TODO
        },
        async openSYQDialog(type) {
            // 查看文保单位所有权情况 TODO
            this.$refs.syqDialog.openSYQDialog(type);
        },
        async getGLJGList() {
            let result = await this.GetGLXList({
                itemcode: "gljg",
                lm: "",
            });
            this.gljgList = result.ResultValue;
        },
        async openZC(zcInfo, index) {
            // 获取某个组成详情
            this.nowZcName = zcInfo.wwdmc;
            this.nowZcIndex = index;
            this.$refs.zyzcDialog.openZYZCDialog(zcInfo);
        },
        addZYZC() {
            this.nowZcName = "";
            this.nowZcIndex = -1;
            this.$refs.zyzcDialog.openZYZCDialog();
        },
        addZYZCItem(info) {
            this.formdata.saveJson.wbdwzyzclist.push(info);
        },
        deleteZYZCItem() {
            let list = this.formdata.saveJson.wbdwzyzclist;
            list.splice(this.nowZcIndex, 1);
        },
        saveSyqInfo() {
            this.formdata.saveJson.wbdwjbxx.syqdwmc =
                this.formdata.saveJson.wbdwxsyqk.syqdwmc;
        },
        // 坐标拾取
        clickPoint() {
            if (this.formdata.saveJson.wbdwjbxx.jwd) {
                let lnglat =
                    this.formdata.saveJson.wbdwjbxx.jwd.split(",") || [];
                this.formdata.saveJson.wbdwjbxx.jd = lnglat[0];
                this.formdata.saveJson.wbdwjbxx.wd = lnglat[1];
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.gray-style {
    color: #606266;
    cursor: pointer;
}
</style>
